
import { defineComponent, ref } from 'vue'
import { HomeOutlined, ArrowLeftOutlined, DeleteOutlined, FilterOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  components: {
    HomeOutlined,
    ArrowLeftOutlined
  },
  setup () {
    return {
    }
  }
})
